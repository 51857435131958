import fetch from 'auth/FetchInterceptor'

const whitelabelService = {}

whitelabelService.getWhitelabelUsers = function () {
    return fetch({
        url: '/whitelabel/users',
        method: 'get'
    })
}

whitelabelService.addWhiteLabelUser = function (data) {
    return fetch({
        url: '/whitelabel/user',
        method: 'post',
        data
    })
}

whitelabelService.updateWhiteLabelUser = function (data) {
    return fetch({
        url: '/whitelabel/user',
        method: 'put',
        data
    })
}

whitelabelService.deleteWhiteLabelUser = function (id) {
    return fetch({
        url: `/whitelabel/user/${id}`,
        method: 'delete'
    })
}

whitelabelService.addWhiteLabelSettinngs = function (data) {
    return fetch({
        url: '/whitelabel/settings',
        method: 'post',
        data
    })
}

whitelabelService.getWhiteLabelSettings = function () {
    return fetch({
        url: '/whitelabel/settings',
        method: 'get'
    })
}

whitelabelService.getDataFromDomain = function (domain) {
    return fetch({
        url: `/search-by-domain`,
        method: 'post',
        data: domain
    })
}


export default whitelabelService