import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN, TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const unauthorizedCode = [ 401, 403]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	const refresh_token = localStorage.getItem('refresh_token') || null;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
		config.headers['refresh_token'] = refresh_token
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {
	let notificationParam = {
		message: ''
	}

	// Remove token and redirect 
	if (unauthorizedCode.includes(error.response?.status)) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)

		store.dispatch(signOutSuccess())
	}

	if (error.response.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
	}

	if(error.response.status === 400){
		if(error?.response?.data?.message){
		notificationParam.message = error.response.data.message
		}
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service